<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Login form</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent="login">
              <v-text-field
                v-model="email"
                ref="email"
                label="Email"
                prepend-icon="mdi-account-circle"
                @blur="$v.email.$touch()"
              />
              <div class="red--text text--lighten-1" v-if="$v.email.$error">
                <div v-if="!$v.email.required">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Email is required
                </div>
                <div v-if="!$v.email.email">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Invalid email address.
                </div>
              </div>

              <v-text-field
                v-model="password"
                ref="password"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                @blur="$v.password.$touch()"
              />

              <div
                class="red--text text--lighten-1 pb-5"
                v-if="$v.password.$error && !$v.password.required"
              >
                <v-icon color="red">mdi-alert-circle-outline</v-icon>
                Password is required
              </div>

              <v-btn
                type="submit"
                color="success"
                name="button"
                :loading="loading"
                :disabled="$v.$invalid"
              >
                Login now
              </v-btn>

              <v-btn
                text
                small
                color="primary"
                :to="{ name: 'forgotPassword' }"
              >
                Forgot your password?
              </v-btn>

              <div v-if="error" class="red--text text--lighten-1 pt-3">
                <v-icon color="red">mdi-alert-circle-outline</v-icon>
                {{ getLoginErrorMsg() }}
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Login",

  data() {
    return {
      email: "",
      password: "",
      error: null,
      showPassword: false,
      loading: false,
    };
  },

  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },

  // isLoggedIn() {
  //   return this.$store.getters["user/loggedIn"];
  // },

  methods: {
    login() {
      this.loading = true;
      this.$store
        .dispatch("user/login", {
          username: this.email,
          password: this.password,
        })
        .then(() => {
          this.loading = false;
          if (this.$store.getters["user/passwordChangeRequired"]) {
            //console.log("push to changePassword");
            this.$router.push({ name: "changePassword" });
          } else {
            this.$router.push({ path: this.$route.query.redirect || "/" });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.error = err.response;
        });
    },

    getLoginErrorMsg() {
      if (this.error.status === 401) {
        return "Invalid username or password";
      } else {
        // return `Login failed: {this.error.statusText}`;
        return "failed";
      }
    },
  },

  mounted() {
    const last_user = localStorage.getItem("last_user");
    if (last_user) {
      this.email = last_user;
      this.$refs.password.focus();
    } else {
      this.$refs.email.focus();
    }
  },
};
</script>

<style></style>
